import React from 'react';
import Layout from '../components/Layout'
import { PageHeader } from '../components/PageHeader';
import { graphql, Link } from 'gatsby';
import SEO from '../components/SEO';

const NotFoundPage = (props) => {
  const { data, location } = props;
  const { wordpressWpSettings } = data;
  const { title } = wordpressWpSettings;
  const h2Style = {
    color: '#3F3F3F',
  };
  console.log('location',location.pathname)
  /* page content is styled in common.scss */
  return (
    <Layout pageClassName="page-404">
      <SEO title={`404 | ${title}`} />
      {/* <PageHeader headerTitle="404 &ndash; Not Found" headerSubTitle={`The page "${location.pathname}" cannot be found.`} headerBackgroundImage="/images/404-hero.jpg" compact={false} location={location} /> */}
      <PageHeader headerTitle="404 &ndash; Back up there tiger, you are officially lost!" headerSubTitle="" headerBackgroundImage="/images/404-hero.jpg" compact={false} location={location} />
      <div className="outer">
        <div className="wrap">
          <h2 style={h2Style}>Let's try and get back on the right path...</h2>
          <ul>
            <li><Link to="/">Homepage</Link></li>
            <li><Link to="/blog/">Latest News</Link></li>
            <li><Link to="/contact-us/">Contact Us</Link></li>
          </ul>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    wordpressWpSettings {
      title
    }
  }
`
